// utils
import { paramCase } from 'src/utils/change-case';
import { _id, _postTitles } from 'src/_mock/assets';

// ----------------------------------------------------------------------

const MOCK_ID = _id[1];

const MOCK_TITLE = _postTitles[2];

const ROOTS = {
  AUTH: '/auth',
  AUTH_DEMO: '/auth-demo',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------


const backend_dev = {
  portfolioManagement: 'http://localhost:4000',
  universeManagement: 'http://localhost:8000',
}

const backend_prod = {
  portfolioManagement: 'https://portfolio-management.cgnal.trade',
  universeManagement: 'https://universe-management.cgnal.trade',
}

const backend = process.env.NODE_ENV === 'production' ? backend_prod : backend_dev

export const paths = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  docs: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
  zoneUI: 'https://mui.com/store/items/zone-landing-page/',
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  freeUI: 'https://mui.com/store/items/minimal-dashboard-free/',
  figma:
    'https://www.figma.com/file/kAYnYYdib0aQPNKZpgJT6J/%5BPreview%5D-Minimal-Web.v5.0.0?type=design&node-id=0%3A1&t=Al4jScQq97Aly0Mn-1',
  product: {
    root: `/product`,
    checkout: `/product/checkout`,
    details: (id) => `/product/${id}`,
    demo: {
      details: `/product/${MOCK_ID}`,
    },
  },
  post: {
    root: `/post`,
    details: (title) => `/post/${paramCase(title)}`,
    demo: {
      details: `/post/${paramCase(MOCK_TITLE)}`,
    },
  },
  backend,
  // AUTH
  auth: {
    amplify: {
      login: `${ROOTS.AUTH}/amplify/login`,
      verify: `${ROOTS.AUTH}/amplify/verify`,
      register: `${ROOTS.AUTH}/amplify/register`,
      newPassword: `${ROOTS.AUTH}/amplify/new-password`,
      forgotPassword: `${ROOTS.AUTH}/amplify/forgot-password`,
    },
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
    firebase: {
      login: `${ROOTS.AUTH}/firebase/login`,
      verify: `${ROOTS.AUTH}/firebase/verify`,
      register: `${ROOTS.AUTH}/firebase/register`,
      forgotPassword: `${ROOTS.AUTH}/firebase/forgot-password`,
    },
    auth0: {
      login: `${ROOTS.AUTH}/auth0/login`,
    },
  },
  authDemo: {
    classic: {
      login: `${ROOTS.AUTH_DEMO}/classic/login`,
      register: `${ROOTS.AUTH_DEMO}/classic/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/classic/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/classic/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/classic/verify`,
    },
    modern: {
      login: `${ROOTS.AUTH_DEMO}/modern/login`,
      register: `${ROOTS.AUTH_DEMO}/modern/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/modern/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/modern/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/modern/verify`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    portfolios: {
      realtime: `${ROOTS.DASHBOARD}/portfolios/realtime`,
      executionPlan: `${ROOTS.DASHBOARD}/portfolios/execution-plan`,
      orders: `${ROOTS.DASHBOARD}/portfolios/orders`,
      positions: `${ROOTS.DASHBOARD}/portfolios/positions`,
      diagnostics: `${ROOTS.DASHBOARD}/portfolios/diagnostics`,
      configuration: `${ROOTS.DASHBOARD}/portfolios/configurations`,
    },
    rawData: {
      eod: `${ROOTS.DASHBOARD}/raw-data/eod`,
      fundamentals: `${ROOTS.DASHBOARD}/raw-data/fundamentals`,
      mna: `${ROOTS.DASHBOARD}/raw-data/mna`,
    },
    datasets: `${ROOTS.DASHBOARD}/datasets`,
    universes: `${ROOTS.DASHBOARD}/universes/list`,
    predictions: `${ROOTS.DASHBOARD}/predictions`,
    models: `${ROOTS.DASHBOARD}/models`,
    numerai: {
      submissions: `${ROOTS.DASHBOARD}/numerai/submissions`,
      configurations: `${ROOTS.DASHBOARD}/numerai/configurations`,
    }
  },
};
